<template>
  <v-container fluid class="text-center">
    <v-breadcrumbs :items="path" large></v-breadcrumbs>
    <v-toolbar-title dense dark class="title text-left mt-5 mx-5">
      <v-row>
        <v-col cols="12" md="3" xs="12"> Mis Campañas </v-col>
        <v-col cols="12" md="9" xs="12" class="text-end" style="white-space: normal">
          <span class="caption grey--text darken-2" style="font-family: 'Quicksand', sans-serif !important">
            Las campañas nos ayudan a organizar la información de los mensajes que enviaremos, puedes crear una campaña
            desde cero o bien copiar la información de una previa e iniciar a editar.
          </span>
        </v-col>
      </v-row>
    </v-toolbar-title>
    <v-card class="elevation-5 mt-5 mx-5">
      <v-card-title class="justify-space-between" style="gap: 20px">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          class="mx-2"
          single-line
          hide-details
          maxlength="150"
          style="max-width: 400px"
        ></v-text-field>
        <div>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="primary"
            class="mx-2 custom-button"
            @click="downloadXLSX('ejecucion_a_demanda')"
          >
            P. Ejecución
            <v-icon right>mdi-microsoft-excel</v-icon>
          </v-btn>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="primary"
            class="mx-2 custom-button"
            @click="downloadXLSX('fecha_programada')"
          >
            P. Programada
            <v-icon right>mdi-microsoft-excel</v-icon>
          </v-btn>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="primary"
            class="mx-2 custom-button"
            @click="downloadXLSX('fecha_variable')"
          >
            P. Fecha Variable
            <v-icon right>mdi-microsoft-excel</v-icon>
          </v-btn>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="primary"
            class="mx-2 custom-button"
            @click="refresh()"
          >
            Actualizar
            <v-icon right>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="primary"
            class="mx-2 custom-button"
            @click="exportarCSV()"
          >
            Exportar
            <v-icon right>mdi-file-export-outline</v-icon>
          </v-btn>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="green"
            class="mx-2 custom-button white--text"
            @click="
              dialogs.new = true;
              isCopy = false;
            "
          >
            Nueva campaña
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :items="items"
        :loading="loading"
        hide-default-headers
        :search="search"
        :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
        :items-per-page="10"
        item-key="id_campaign"
        class="locked-columns-table elevation-1"
        dense
      >
        <template v-if="this.$vuetify.breakpoint.smAndUp" v-slot:header="{ header }">
          <thead>
            <tr>
              <th class="locked-column">{{ "Estatus" }}</th>
              <th class="locked-column__second text-center">{{ "Nombre" }}</th>
              <th>{{ "Categoría" }}</th>
              <th>{{ "Subcategoría" }}</th>
              <th class="text-center">{{ "Tipo" }}</th>
              <th>{{ "Fecha inicio" }}</th>
              <th>{{ "Fecha fin" }}</th>
              <th>{{ "Compleatado" }}</th>
              <th>{{ "Resumen" }}</th>
              <th>{{ "Costo (USD)" }}</th>
              <th class="text-center">{{ "Acciones" }}</th>
            </tr>
          </thead>
        </template>
        <template v-if="this.$vuetify.breakpoint.smAndUp" v-slot:item="{ item }">
          <tr style="height: 135px">
            <td class="locked-column" style="position: sticky; left: 0; background-color: #f5f5f5; z-index: 3">
              <v-switch
                @click="updateStatus(item.id_campaign)"
                v-model="item.active"
                :color="item.active ? 'green' : 'error'"
                :value="item.active"
                hide-details
              ></v-switch>
            </td>
            <td class="locked-column__second">{{ item.name }}</td>
            <td class="column-border__right">{{ item.category.name }}</td>
            <td class="column-border__right">{{ item.subcategory.name }}</td>
            <td class="column-border__right">{{ getType(item.type) }}</td>
            <td class="column-border__right">{{ item.start_date }}</td>
            <td class="column-border__right">{{ item.end_date }}</td>
            <td class="column-border__right">
              <v-icon v-if="item.complete" color="success" class="mx-1">mdi-check-circle</v-icon>
              <v-icon v-else color="grey" class="mx-1">mdi-check-circle</v-icon>
            </td>
            <td class="column-border__right">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.summary }}</span>
                </template>
                <span>Entregados / Capturados</span>
              </v-tooltip>
            </td>
            <td class="column-border__right">{{ item.total_cost }}</td>
            <td style="display: flex; align-items: center; height: 135px">
              <v-tooltip left
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    class="mr-2"
                    size="22"
                    color="primary"
                    style="height: 22px"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :disabled="loading"
                    @click="view(item)"
                    >mdi-pencil</v-icon
                  ></template
                ><span>Editar</span></v-tooltip
              >
              <v-tooltip left
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    class="mr-2"
                    size="22"
                    color="primary"
                    style="height: 22px"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :disabled="loading"
                    @click="updateCost(item)"
                    >mdi-currency-usd</v-icon
                  ></template
                ><span>Actualizar costos</span></v-tooltip
              >
              <v-tooltip top
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    class="mr-2"
                    size="22"
                    color="primary"
                    style="height: 22px"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :disabled="loading"
                    @click="showMessages(item.id_campaign)"
                    >mdi-message-text</v-icon
                  ></template
                ><span>Mensajes</span></v-tooltip
              >
              <v-tooltip top
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    class="mr-2"
                    size="22"
                    color="error"
                    style="height: 22px"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :disabled="loading"
                    @click="remove(item.id_campaign)"
                    >mdi-delete</v-icon
                  ></template
                ><span>Eliminar</span></v-tooltip
              >
              <v-tooltip right
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    class="mr-2"
                    size="22"
                    color="primary"
                    style="height: 22px"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :disabled="loading"
                    @click="copy(item)"
                    >mdi-content-copy</v-icon
                  ></template
                ><span>Copiar</span></v-tooltip
              >
            </td>
          </tr>
        </template>
        <template v-else v-slot:item="{ item }">
          <tr class="mobile-tr">
            <td
              class="mobile-td"
              style="
                min-height: 48px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: none;
              "
            >
              <div class="mobile-header">{{ "Estatus" }}</div>
              <div>
                <v-switch
                  @click="updateStatus(item.id_campaign)"
                  v-model="item.active"
                  :color="item.active ? 'green' : 'error'"
                  :value="item.active"
                  hide-details
                  class="mt-0"
                ></v-switch>
              </div>
            </td>
            <td class="mobile-td">
              <div class="mobile-header">{{ "Nombre" }}</div>
              <div>
                {{ item.name }}
              </div>
            </td>
            <td class="mobile-td">
              <div class="mobile-header">{{ "Categoría" }}</div>
              <div>
                {{ item.category.name }}
              </div>
            </td>
            <td class="mobile-td">
              <div class="mobile-header">{{ "Subcategoría" }}</div>
              <div>
                {{ item.subcategory.name }}
              </div>
            </td>
            <td class="mobile-td">
              <div class="mobile-header">{{ "Tipo" }}</div>
              <div>
                {{ getType(item.type) }}
              </div>
            </td>
            <td class="mobile-td">
              <div class="mobile-header">{{ "Fecha inicio" }}</div>
              <div>
                {{ item.start_date }}
              </div>
            </td>
            <td class="mobile-td">
              <div class="mobile-header">{{ "Fecha fin" }}</div>
              <div>
                {{ item.end_date }}
              </div>
            </td>
            <td class="mobile-td">
              <div class="mobile-header">{{ "Completado" }}</div>
              <div>
                <v-icon v-if="item.complete" color="success" class="mx-1">mdi-check-circle</v-icon>
                <v-icon v-else color="grey" class="mx-1">mdi-check-circle</v-icon>
              </div>
            </td>
            <td class="mobile-td">
              <div class="mobile-header">{{ "Resumen" }}</div>
              <div>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.summary }}</span>
                  </template>
                  <span>Entregados / Capturados</span>
                </v-tooltip>
              </div>
            </td>
            <td class="mobile-td">
              <div class="mobile-header">{{ "Costo (USD)" }}</div>
              <div>
                {{ item.total_cost }}
              </div>
            </td>
            <td class="mobile-td" style="border-bottom: thin solid rgba(0, 0, 0, 0.12) !important">
              <div class="mobile-header">{{ "Acciones" }}</div>
              <div>
                <v-tooltip left
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon
                      class="mr-2"
                      size="22"
                      color="primary"
                      style="height: 22px"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loading"
                      @click="view(item)"
                      >mdi-pencil</v-icon
                    ></template
                  ><span>Editar</span></v-tooltip
                >
                <v-tooltip left
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon
                      class="mr-2"
                      size="22"
                      color="primary"
                      style="height: 22px"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loading"
                      @click="updateCost(item)"
                      >mdi-currency-usd</v-icon
                    ></template
                  ><span>Actualizar costos</span></v-tooltip
                >
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon
                      class="mr-2"
                      size="22"
                      color="primary"
                      style="height: 22px"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loading"
                      @click="showMessages(item.id_campaign)"
                      >mdi-message-text</v-icon
                    ></template
                  ><span>Mensajes</span></v-tooltip
                >
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon
                      class="mr-2"
                      size="22"
                      color="error"
                      style="height: 22px"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loading"
                      @click="remove(item.id_campaign)"
                      >mdi-delete</v-icon
                    ></template
                  ><span>Eliminar</span></v-tooltip
                >
                <v-tooltip right
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon
                      class="mr-2"
                      size="22"
                      color="primary"
                      style="height: 22px"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loading"
                      @click="copy(item)"
                      >mdi-content-copy</v-icon
                    ></template
                  ><span>Copiar</span></v-tooltip
                >
              </div>
            </td>
          </tr>
        </template>
        <!-- <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <br />
            <v-row>
              <v-col cols="2">
                <strong>Etiquetas</strong>
              </v-col>
              <v-col cols="2">
                <strong>Fecha programado</strong>
              </v-col>
              <v-col cols="2">
                <strong>Fecha de creación</strong>
              </v-col>
              <v-col cols="2">
                <strong>Usuario creación</strong>
              </v-col>
              <v-col cols="2">
                <strong>Usuario actualización</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                {{ item.detail_labels }}
              </v-col>
              <v-col cols="2">
                {{ item.detail_scheduled_date }}
              </v-col>
              <v-col cols="2">
                {{ item.detail_datetime_create }}
              </v-col>
              <v-col cols="2">
                {{ item.detail_user_create }}
              </v-col>
              <v-col cols="2">
                {{ item.detail_user_update }}
              </v-col>
            </v-row>
            <br />
          </td>
        </template>-->
        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title v-if="isCopy">Copiar campaña</v-toolbar-title>
          <v-toolbar-title v-else>Nueva campaña</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-va2lidation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.new.name"
                    label="Nombre"
                    :rules="rules.name"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="forms.new.category"
                    :items="catalogues.categories"
                    item-text="name"
                    item-value="id_category"
                    label="Categoria"
                    :rules="rules.category"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="forms.new.subcategory"
                    :items="catalogues.subcategories"
                    item-text="name"
                    item-value="id_subcategory"
                    label="Subcategoría"
                    :rules="rules.subcategory"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.new.labels"
                    :items="catalogues.labels"
                    label="Etiquetas"
                    item-text="name"
                    item-value="name"
                    :disabled="loading"
                    multiple
                    chips
                    small-chips
                    dense
                    outlined
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="forms.new.comment"
                    label="Comentario"
                    :rules="rules.comment"
                    rows="2"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.new.type"
                    :items="catalogues.types"
                    label="Tipo"
                    item-text="text"
                    item-value="value"
                    :rules="rules.type"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" v-show="showScheduledDate">
                  <v-dialog
                    ref="scheduledDateDialog"
                    v-model="dialogs.scheduled_date"
                    :return-value.sync="forms.new.scheduled_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.new.scheduled_date"
                        label="Fecha programado"
                        :disabled="loading"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="forms.new.scheduled_date" locale="es" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogs.scheduled_date = false"> Cancelar </v-btn>
                      <v-btn text color="primary" @click="$refs.scheduledDateDialog.save(forms.new.scheduled_date)">
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6" v-show="showScheduledDate">
                  <v-dialog
                    ref="scheduledHourDialog"
                    v-model="dialogs.scheduled_hour"
                    :return-value.sync="forms.new.scheduled_hour"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.new.scheduled_hour"
                        label="Hora programado"
                        :disabled="loading"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-model="forms.new.scheduled_hour" locale="es" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogs.scheduled_hour = false"> Cancelar </v-btn>
                      <v-btn text color="primary" @click="$refs.scheduledHourDialog.save(forms.new.scheduled_hour)">
                        Aceptar
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="startDateDialog"
                    v-model="dialogs.start_date"
                    :return-value.sync="forms.new.start_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.new.start_date"
                        label="Fecha inicio"
                        :rules="rules.start_date"
                        :disabled="loading"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="forms.new.start_date" locale="es" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogs.start_date = false"> Cancelar </v-btn>
                      <v-btn text color="primary" @click="$refs.startDateDialog.save(forms.new.start_date)">
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="endDateDialog"
                    v-model="dialogs.end_date"
                    :return-value.sync="forms.new.end_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.new.end_date"
                        label="Fecha fin"
                        :rules="rules.end_date"
                        :disabled="loading"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="forms.new.end_date" locale="es" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogs.end_date = false"> Cancelar </v-btn>
                      <v-btn text color="primary" @click="$refs.endDateDialog.save(forms.new.end_date)">
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.new.provider_setting"
                    :items="catalogues.providers"
                    label="Configuración de proveedor"
                    item-text="name"
                    item-value="id_provider_setting"
                    :rules="rules.provider_setting"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-divider class="ma-5"></v-divider>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-textarea
                    v-model="forms.new.template"
                    label="Plantilla"
                    counter="160"
                    :rules="rules.template"
                    rows="3"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Actualizar campaña</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.name"
                    label="Nombre"
                    :rules="rules.name"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="forms.update.category"
                    :items="catalogues.categories"
                    item-text="name"
                    item-value="id_category"
                    label="Categoria"
                    :rules="rules.category"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="forms.update.subcategory"
                    :items="catalogues.subcategories"
                    item-text="name"
                    item-value="id_subcategory"
                    label="Subcategoría"
                    :rules="rules.subcategory"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.update.labels"
                    :items="catalogues.labels"
                    item-text="name"
                    item-value="name"
                    label="Etiquetas"
                    :disabled="loading"
                    multiple
                    dense
                    outlined
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="forms.update.comment"
                    label="Comentario"
                    :rules="rules.comment"
                    rows="2"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.update.type"
                    :items="catalogues.types"
                    item-text="text"
                    item-value="value"
                    label="Tipo"
                    :rules="rules.type"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" v-show="showScheduledDate">
                  <v-dialog
                    ref="scheduledDateDialog"
                    v-model="dialogs.scheduled_date"
                    :return-value.sync="forms.update.scheduled_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.update.scheduled_date"
                        label="Fecha programado"
                        :disabled="loading"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="forms.update.scheduled_date" locale="es" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogs.scheduled_date = false"> Cancelar </v-btn>
                      <v-btn text color="primary" @click="$refs.scheduledDateDialog.save(forms.update.scheduled_date)">
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6" v-show="showScheduledDate">
                  <v-dialog
                    ref="scheduledHourDialog"
                    v-model="dialogs.scheduled_hour"
                    :return-value.sync="forms.update.scheduled_hour"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.update.scheduled_hour"
                        label="Hora programado"
                        :disabled="loading"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-model="forms.update.scheduled_hour" locale="es" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogs.scheduled_hour = false"> Cancelar </v-btn>
                      <v-btn text color="primary" @click="$refs.scheduledHourDialog.save(forms.update.scheduled_hour)">
                        Aceptar
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="startDateDialog"
                    v-model="dialogs.start_date"
                    :return-value.sync="forms.update.start_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.update.start_date"
                        label="Fecha inicio"
                        :rules="rules.start_date"
                        :disabled="loading"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="forms.update.start_date" locale="es" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogs.start_date = false"> Cancelar </v-btn>
                      <v-btn text color="primary" @click="$refs.startDateDialog.save(forms.update.start_date)">
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="endDateDialog"
                    v-model="dialogs.end_date"
                    :return-value.sync="forms.update.end_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.update.end_date"
                        label="Fecha fin"
                        :rules="rules.end_date"
                        :disabled="loading"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="forms.update.end_date" locale="es" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogs.end_date = false"> Cancelar </v-btn>
                      <v-btn text color="primary" @click="$refs.endDateDialog.save(forms.update.end_date)">
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.update.provider_setting"
                    :items="catalogues.providers"
                    label="Configuración de proveedor"
                    item-text="name"
                    item-value="id_provider_setting"
                    :rules="rules.provider_setting"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-divider class="ma-5"></v-divider>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-textarea
                    v-model="forms.update.template"
                    label="Plantilla"
                    counter="160"
                    :rules="rules.template"
                    rows="3"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar la campaña?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from "@/utils/services";
import moment from "moment-timezone";
import { Parser } from "json2csv";
import _ from "lodash";

export default {
  name: "campaign",
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    loading: false,
    success: false,
    msgSuccess: "",
    error: false,
    msgError: "",
    search: "",
    path: [
      {
        text: "SMS",
        disabled: false,
        href: "home",
      },
      {
        text: "Camapañas",
        disabled: false,
        href: "",
      },
    ],
    headers: [
      { text: "Estatus", align: "left", sortable: false, value: "active" },
      { text: "Nombre", align: "left", sortable: false, value: "name" },
      { text: "Categoría", align: "left", sortable: false, value: "category.name" },
      { text: "Subcategoría", align: "left", sortable: false, value: "subcategory.name" },
      { text: "Tipo", align: "left", sortable: false, value: "type" },
      { text: "Fecha inicio", align: "left", sortable: false, value: "start_date" },
      { text: "Fecha fin", align: "left", sortable: false, value: "end_date" },
      { text: "Completado", align: "left", sortable: false, value: "complete" },
      { text: "Resumen", align: "left", sortable: false, value: "summary" },
      { text: "Costo (USD)", align: "left", sortable: false, value: "total_cost" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    selected: [],
    deleted: null,
    isCopy: false,
    catalogues: {
      providers: [],
      categories: [],
      subcategories: [],
      labels: [],
      types: [],
    },
    forms: {
      new: {
        name: "",
        category: "",
        subcategory: "",
        labels: [],
        comment: "",
        type: "",
        scheduled_date: null,
        scheduled_hour: null,
        start_date: "",
        end_date: "",
        provider_setting: "",
        template: "",
      },
      update: {
        id_campaign: "",
        name: "",
        category: "",
        subcategory: "",
        labels: [],
        comment: "",
        type: "",
        scheduled_date: null,
        scheduled_hour: null,
        start_date: "",
        end_date: "",
        provider_setting: "",
        template: "",
      },
    },
    rules: {
      name: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length <= 100) || "El campo excede la longitud máxima",
      ],
      category: [(v) => !!v || "El campo es requerido"],
      subcategory: [(v) => !!v || "El campo es requerido"],
      comment: [(v) => _.isNil(v) || (!_.isNil(v) && v.length <= 1000) || "El campo excede la longitud máxima"],
      type: [(v) => !!v || "El campo es requerido"],
      start_date: [(v) => !!v || "El campo es requerido"],
      end_date: [(v) => !!v || "El campo es requerido"],
      provider_setting: [(v) => !!v || "El campo es requerido"],
      template: [(v) => _.isNil(v) || (!_.isNil(v) && v.length <= 500) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      new: false,
      update: false,
      remove: false,
      scheduled_date: false,
      start_date: false,
      end_date: false,
    },
  }),
  mounted() {
    this.getCatalogues();
    this.refresh();
  },
  computed: {
    showScheduledDate() {
      if (this.dialogs.new) {
        return this.forms.new.type === "SCHEDULED_DATE" ? true : false;
      } else if (this.dialogs.update) {
        return this.forms.update.type === "SCHEDULED_DATE" ? true : false;
      }
      return false;
    },
  },
  methods: {
    async getCatalogues() {
      const provider = await this.axios.get(services.routes.provider);
      if (provider.data.data) {
        this.catalogues.providers = provider.data.data;
      }
      const category = await this.axios.get(services.routes.category);
      if (category.data.data) {
        this.catalogues.categories = category.data.data;
      }
      const subcategory = await this.axios.get(services.routes.subcategory);
      if (subcategory.data.data) {
        this.catalogues.subcategories = subcategory.data.data;
      }
      const label = await this.axios.get(services.routes.label);
      if (label.data.data) {
        this.catalogues.labels = label.data.data;
      }
      const type = await this.axios.get(services.routes.campaign + "/type");
      if (type.data.data) {
        this.catalogues.types = type.data.data;
      }
    },
    refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.campaign)
        .then((response) => {
          this.items = response.data.data;
          this.items.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    getType(text) {
      for (let elem of this.catalogues.types) {
        if (text === elem.value) {
          return elem.text;
        }
      }
      return "";
    },
    exportarCSV() {
      const configuracion = {
        fields: [
          { label: "Nombre", value: "name" },
          { label: "Categoría", value: "category.name" },
          { label: "Subcategoría", value: "subcategory.name" },
          { label: "Tipo", value: "type" },
          { label: "Fecha inicio", value: "start_date" },
          { label: "Fecha fin", value: "end_date" },
          { label: "Estatus", value: "active" },
          { label: "Completado", value: "complete" },
          { label: "Resumen", value: "summary" },
          { label: "Costo (USD)", value: "total_cost" },
          { label: "Acciones", value: "actions" },
        ],
      };
      const parser = new Parser(configuracion);
      const csv = parser.parse(this.items);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "tabla.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          if (key === "labels") {
            this.forms.new[key] = [];
          } else if (key === "scheduled_date") {
            this.forms.new[key] = null;
          } else if (key === "scheduled_hour") {
            this.forms.new[key] = null;
          } else {
            this.forms.new[key] = "";
          }
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.$refs.formUpdate.reset();
        for (let key in this.forms.update) {
          if (key === "labels") {
            this.forms.update[key] = [];
          } else if (key === "scheduled_date") {
            this.forms.update[key] = null;
          } else if (key === "scheduled_hour") {
            this.forms.update[key] = null;
          } else {
            this.forms.update[key] = "";
          }
        }
        this.dialogs.update = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        if (data.scheduled_date) {
          data.scheduled_date = moment.utc(moment(data.scheduled_date + " " + data.scheduled_hour)).format();
        }
        this.axios
          .post(services.routes.campaign, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Campaña creada exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
    view(data) {
      let scheduledDate = data.scheduled_date
        ? moment.tz(data.scheduled_date, "UTC").local().format("YYYY-MM-DD")
        : null;
      let scheduledHour = data.scheduled_date ? moment.tz(data.scheduled_date, "UTC").local().format("HH:mm") : null;
      this.forms.update = {
        id_campaign: data.id_campaign,
        name: data.name,
        category: data.category.id_category,
        subcategory: data.subcategory.id_subcategory,
        labels: data.labels,
        comment: data.comment,
        type: data.type,
        scheduled_date: scheduledDate,
        scheduled_hour: scheduledHour,
        start_date: data.start_date,
        end_date: data.end_date,
        provider_setting: data.provider_setting.id_provider_setting,
        template: data.template,
      };
      this.dialogs.update = true;
    },
    updateCost(_data) {
      this.loading = true;
      let data = {
        id_campaign: _data.id_campaign,
      };
      this.items = [];
      this.axios
        .post(services.routes.campaign + "/message/update/cost", { data })
        .then((response) => {
          this.success = true;
          this.msgSuccess = "Costos actualizados";
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.refresh();
        });
    },
    copy(data) {
      let scheduledDate = data.scheduled_date
        ? moment.tz(data.scheduled_date, "UTC").local().format("YYYY-MM-DD")
        : null;
      let scheduledHour = data.scheduled_date ? moment.tz(data.scheduled_date, "UTC").local().format("HH:mm") : null;

      this.forms.new = {
        id_campaign: data.id_campaign,
        name: `${data.name} - copia`,
        category: data.category.id_category,
        subcategory: data.subcategory.id_subcategory,
        labels: data.labels,
        comment: data.comment,
        type: data.type,
        scheduled_date: scheduledDate,
        scheduled_hour: scheduledHour,
        start_date: data.start_date,
        end_date: data.end_date,
        provider_setting: data.provider_setting.id_provider_setting,
        template: data.template,
      };
      this.isCopy = true;
      this.dialogs.new = true;
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        if (data.scheduled_date) {
          data.scheduled_date = moment.utc(moment(data.scheduled_date + " " + data.scheduled_hour)).format();
        }
        this.axios
          .put(services.routes.campaign, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Campaña actualizada exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.campaign, { data: { data: { id_campaign: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Campaña eliminada exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
    updateStatus(id) {
      this.loading = true;
      this.axios
        .put(services.routes.campaign + "/updateStatus", { data: { campaign: id } })
        .then((response) => {
          this.cancel();
          this.refresh();
          this.success = true;
          this.msgSuccess = "Campaña actualizada exitosamente";
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showMessages(id) {
      this.$router.push("/campaigns/" + id + "/messages");
    },
    downloadXLSX(type) {
      const url = this.host + "/public-files/" + type + ".xlsx";

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          // Crear un enlace temporal
          const enlaceTemporal = document.createElement("a");
          enlaceTemporal.href = URL.createObjectURL(blob);
          enlaceTemporal.download = type + ".xlsx";

          // Simular el clic en el enlace para descargar el archivo
          enlaceTemporal.click();

          // Limpiar el objeto URL.createObjectURL
          setTimeout(() => {
            URL.revokeObjectURL(enlaceTemporal.href);
          }, 100);
        })
        .catch((error) => {
          console.error("Error al descargar el archivo:", error);
        });
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Quicksand", sans-serif !important;
  font-size: 2rem !important;
  line-height: 1.2;
}
.locked-columns-table .locked-column {
  position: sticky;
  left: 0;
  background-color: #f5f5f5;
  z-index: 3;
}
.locked-columns-table .locked-column__second {
  position: sticky;
  left: 78px;
  background-color: #f5f5f5;
  z-index: 3;
}
.column-border__right {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.mobile-tr {
  display: flex;
  flex-direction: column;
}
.mobile-td {
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none !important;
}
.mobile-header {
  font-weight: 600;
}
</style>
